import { myApi, myPubApi } from '../utils/apiHelper';

class ProductService {
  async getProducts(queryParam) {
    try {
      return await myPubApi.get(`/getProduct`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getBrandProducts(params) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/getBrandProducts`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getProductsWithPagnation(queryParam) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/getProduct/pagnation`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getBrandProductsWithPagnation(queryParam) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/getBrandProduct/pagnation`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductById(id, data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/productById/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getBrandProductById(id, data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/brandProductById/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async updateProduct(id) {
    try {
      return await myApi.put(`/updateProduct/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductPropById(id) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return api.get(`/productProp/getById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getProductProps(params) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return api.get(`/getProductProps`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getProductBrandNames(params) {
    try {
      return myPubApi.get(`/getProductBrandNames`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getBrandProductBrandNames(params) {
    try {
      return myPubApi.get(`/getBrandProductBrandNames`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getProductCategories(params) {
    try {
      return myPubApi.get(`/getProductCategories`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const productService = new ProductService();
export default productService;
export { productService };
